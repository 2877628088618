<template>
  <div class="information" :class="{ mobile: isMobile }">
    <div class="info-container">
      <div
        class="information-col"
        v-for="(item, index) in items"
        :key="index"
        @click="navigateTo(item.path, item.external)"
      >
        <div :class="['frame', `frame-${index + 1}`]">
          <span class="title">{{ item.text.title }}</span>
          <div class="information-div">
            <img class="img" :alt="`Img ${index + 1}`" :src="item.img" />
          </div>
          <div class="text-frame">
            <div class="text-wrapper">{{ item.text.main }}</div>
            <div class="text-wrapper-2">{{ item.text.sub }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "InfoArea",
  data: () => ({
    items: [
      {
        img: require("@/assets/img/bank.svg"),
        text: {
          title: "입금안내",
          main: "신한은행 / 예금주 주식회사 퓨처온",
          sub: "140-013-618264",
        },
      },
      {
        img: require("@/assets/img/qna.svg"),
        text: {
          title: "문의하기",
          main: "제휴, 상담 및 전문가 관련 문의",
          sub: "",
        },
        path: "https://open.kakao.com/o/sv7GvILg",
        external: true,
      },
      {
        img: require("@/assets/img/service.svg"),
        text: {
          title: "상담 서비스",
          main: "원격으로 지원해주는 상담 서비스\n 근무시간 09:00 ~ 17:00 / 토일 휴무",
          sub: "",
        },
        path: "http://ezh.kr/futureson/",
        external: true,
      },
      {
        img: require("@/assets/img/trader.svg"),
        text: {
          title: "전문가 모집",
          main: "퓨처온에서 Top Class\n 전문가님들을 모집합니다.",
          sub: "",
        },
        path: "https://home.futureon.co.kr/#/notice/view?currentIndex=0&page=1&pageCount=1&limit=5",
        external: true,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  methods: {
    navigateTo(path, external) {
      if (external) {
        window.open(path, "_blank");
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.information {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 18.75rem;
  background-color: #f1f3f8;
  overflow: hidden;
}
.information.mobile {
  display: block;
  padding: 2.5rem 0;
  height: 100%;
  max-height: 35rem;
  overflow: visible;
}

.information .info-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  gap: 2.5rem;
}
.information.mobile .info-container {
  height: auto;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.information-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 5.625rem;
  height: 100%;
  max-height: 5.625rem;
}

.information .information-col {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 18.75rem;
  height: 100%;
  max-height: 17.5rem;
  cursor: pointer;
}
.information.mobile .information-col {
  width: 100%;
  max-width: 6.25rem;
  height: 100%;
  max-height: 11.25rem;
  gap: 0.625rem;
  margin: 0 auto;
}

.information .frame {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 0.625rem;
  padding-top: 15%;
}
.information.mobile .frame {
  gap: 0.625rem;
}

.text-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;
  white-space: pre-line;
}
.information.mobile .text-frame {
  height: auto;
  width: 6.25rem;
}

.information .text-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.information.mobile .text-wrapper {
  font-size: 0.875rem;
  width: 170%;
  white-space: normal;
}

.information .text-wrapper-2 {
  color: var(--grayscale-90);
  font-family: "Pretendard-Medium", Helvetica;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  position: relative;
  width: 100%;
  white-space: nowrap;
}
.information.mobile .text-wrapper-2 {
  font-size: 1.125rem;
  text-align: center;
  white-space: nowrap;
  line-height: 1.25rem;
}
</style>
