<template>
  <section class="swiper-carousel-container" :class="{ mobile: isMobile }" v-if="!isMobile">
    <swiper :options="swiperCarouselOptions" class="swiper-carousel">
      <swiper-slide v-for="slide in slides" :key="slide.expertId" class="swiper-slide-main">
        <div class="carousel-main" :class="{ mobile: isMobile }">
          <div class="carousel-sub">
            <div class="text-btn-group">
              <div class="text-group">
                <div class="sub-title">
                  <div class="broadcast-title">
                    <!-- <div class="title-wrapper">
                      <span>베스트 리딩방송</span>
                      <v-icon>mdi-rhombus</v-icon>
                    </div> -->
                    <div class="trader-wrapper">퓨처온 트레이더</div>
                  </div>
                  <div class="main-stocks">{{ slide.subTitle }}</div>
                </div>
                <div class="contents-description">
                  {{ slide.mainTitle }}
                </div>
              </div>
              <div class="btn-group">
                <button class="BTN1" @click="openVod(slide.expertId)">VOD 보기</button>
                <div class="BTN2" @click="openKakaoTalk(slide.kakaoTalk)">무료카톡방 입장</div>
                <button class="BTN3" @click="openService(slide.expertId)">정회원 가입</button>
              </div>
            </div>
            <div class="expert-broadcast-group">
              <div :class="['expert', { 'image-error': slide.imageError }]">
                <img
                  @error="handleImageError(slide.expertId)"
                  :src="slide.profileImg"
                  alt="expert"
                />
                <div class="rectangle-container">
                  <div class="rectangle">
                    <div class="broadcast-time" @click="joinBroadcast(slide, slide.expertId)">
                      {{ slide.broadcastTime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="FUTUERON">FUTUERON</div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "CarouselView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    swiperCarouselOptions: {
      slidesPerView: "auto",
      spaceBetween: 40,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    },
    slides: [
      {
        expertId: "",
        mainTitle: "",
        subTitle: "",
        profileImg: "",
        broadcastTime: "",
        kakaoTalk: "",
      },
    ],
    defaultSlides: [
      {
        mainTitle: "Global 전문가!\n천명의 Easy 해외투자 실전선물",
        subTitle: "항셍/나스닥/다우/골드",
        broadcastTime: "2.2 방송준비 (22:00 ~ 22:00)",
        profileImg: require("@/assets/1.png"),
      },
      {
        mainTitle: "Global 전문가!\n천명의 Easy 해외투자 실전선물",
        subTitle: "항셍/나스닥/다우/골드",
        broadcastTime: "2.2 방송준비 (22:00 ~ 22:00)",
        profileImg: require("@/assets/1.png"),
      },
      {
        mainTitle: "Global 전문가!\n천명의 Easy 해외투자 실전선물",
        subTitle: "항셍/나스닥/다우/골드",
        broadcastTime: "2.2 방송준비 (22:00 ~ 22:00)",
        profileImg: require("@/assets/1.png"),
      },
    ],
    defaultLogo: require("@/assets/img/futureon-logo-white-1.png"),
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
  },
  mounted() {
    this.fetchSlides();
  },
  methods: {
    ...mapActions(["validateToken"]),
    async fetchSlides() {
      try {
        const { data } = await this.$axios.get(
          `${process.env.VUE_APP_API_URL}/users/search_expert_swiper`
        );

        this.slides = data
          .map((expert) => {
            if (!expert.profile_img || !expert.main_title) {
              return null;
            }
            return {
              expertId: expert.expert_id,
              kakaoTalk: expert.kakao_talk,
              mainTitle: expert.main_title,
              subTitle: expert.sub_title,
              broadcastTime: this.formatBroadcastTime(
                expert.start_date,
                expert.end_date,
                expert.broadcast_status
              ),
              profileImg: expert.profile_img,
              leaved: expert.leaved,
              broadcastStatus: expert.broadcast_status,
              type: expert.type,
            };
          })
          .filter((expert) => expert !== null && expert.leaved === 0);
      } catch (error) {
        console.log("Failed to fetch expert data.");
      }
    },
    formatBroadcastTime(start, end, status) {
      const statusMessages = {
        0: "방송전",
        1: "방송중",
        2: "방송종료",
      };
      const formattedStartDate = start ? this.$dayjs(start).tz("Asia/Seoul").format("MM.DD") : "";
      const formattedStartTime = start ? this.$dayjs(start).tz("Asia/Seoul").format("HH:mm") : "";
      const formattedEndTime = end ? this.$dayjs(end).tz("Asia/Seoul").format("HH:mm") : "";
      if (!formattedStartDate || !formattedStartTime || !formattedEndTime) {
        return "방송준비중";
      }
      return `${formattedStartDate} ${statusMessages[status]} (${formattedStartTime} ~ ${formattedEndTime})`;
    },
    openKakaoTalk(link) {
      if (!link) {
        console.log("No link provided.");
        return;
      }
      window.open(link, "_blank");
    },
    openVod(id) {
      this.$router.push({ name: "FuturesVod", params: { expert_id: id } });
    },
    openService(id) {
      this.$router.push({ name: "LineupLayout", params: { expert_id: id, activeBtn: "content3" } });
    },
    async joinBroadcast(data, expert_id) {
      if (data.broadcastStatus === 1) {
        try {
          let userId = null;
          let reqData = {};

          if (data.type === 0) {
            const userToken = await this.validateToken();

            if (
              !userToken ||
              !userToken.data ||
              !userToken.data.user ||
              userToken.data.user.length === 0
            ) {
              throw new Error("Invalid token data");
            }
            userId = userToken.data.user[0].user_id;
            reqData = {
              user_id: userId,
              expert_id: expert_id,
              base_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "LineupLayout",
                }).href
              }`,
              register_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "RegisterView",
                }).href
              }`,
            };
          } else if (data.type === 1) {
            const userToken = await this.validateToken().catch(() => {
              reqData = { expert_id: expert_id };
            });

            if (
              userToken &&
              userToken.data &&
              userToken.data.user &&
              userToken.data.user.length > 0
            ) {
              userId = userToken.data.user[0].user_id;
              reqData = {
                user_id: userId,
                expert_id: expert_id,
                base_url: `${window.location.origin}/${
                  this.$router.resolve({
                    name: "LineupLayout",
                  }).href
                }`,
                register_url: `${window.location.origin}/${
                  this.$router.resolve({
                    name: "RegisterView",
                  }).href
                }`,
              };
            }
          }

          const joinUrl = await this.$axios.post(
            `${process.env.VUE_APP_API_URL}/content/join_broadcast`,
            reqData
          );

          if (joinUrl.data.url && joinUrl.status === 200) {
            window.open(joinUrl.data.url, "_blank");
          } else {
            alert("방송중이 아닙니다.");
          }
        } catch (error) {
          console.error("Error in joinBroadcast:", error.message);
          if (error.response && error.response.status === 401) {
            const confirmation = confirm("로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?");
            if (confirmation) {
              this.$router.push("/login");
            }
          } else {
            alert("방송중이 아닙니다.");
          }
        }
      }
    },
    handleImageError(expertId) {
      const index = this.slides.findIndex((slide) => slide.expertId === expertId);
      if (index !== -1) {
        this.$set(this.slides, index, {
          ...this.slides[index],
          imageError: true,
        });
      }
    },
  },
};
</script>
<style>
.swiper-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  height: 100vh;
  max-height: 37.5rem;
}
.swiper-carousel-container.mobile {
  max-height: 34.5rem;
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}

.swiper-carousel {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.swiper-carousel .swiper-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
}
/* .swiper-carousel-container.mobile .swiper-carousel {
} */

.swiper-slide-main {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  height: 100%;
  max-height: 34.375rem;
}
.swiper-carousel-container.mobile .swiper-slide-main {
  max-width: 25rem;
}

.carousel-main {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #24324b;
  border-radius: 1.875rem;
  cursor: default;
}
.carousel-main.mobile {
  gap: 1.25rem;
  max-width: 25rem;
  max-height: 30rem;
}

.carousel-sub {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 34.375rem;
}
.carousel-main.mobile .carousel-sub {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 22rem;
  height: 100%;
  max-height: 30rem;
}

.text-btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: 48.75rem;
  height: 30.625rem;
  gap: 2.5rem;
}
.carousel-main.mobile .text-btn-group {
  width: 100%;
  max-width: 20rem;
  height: 100%;
  max-height: 11.5rem;
  gap: 0.75rem;
  margin-top: auto;
}

.text-group {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  gap: 1.25rem;
}
.carousel-main.mobile .text-group {
  gap: 0.75rem;
}

.sub-title {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  gap: 0.75rem;
}
.carousel-main.mobile .sub-title {
  gap: 0.5rem;
}

.broadcast-title {
  display: flex;
  position: relative;
  width: 21.438rem;
  height: 2.125rem;
  justify-content: flex-start;
  align-items: center;
  gap: 8%;
}

.title-wrapper {
  display: inline-flex;
  position: relative;
  color: #ffffff;
  white-space: nowrap;
  text-align: center;
  background-color: #4589ff;
  border-right: 1px solid #4589ff;
  border-radius: 0.2rem;
  z-index: 2;
}
.title-wrapper span {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  z-index: 3;
  padding: 0 0 0 0.75rem;
}
.title-wrapper i {
  top: -10%;
  right: -11%;
  position: absolute !important;
  font-size: 225% !important;
  color: #4589ff !important;
  z-index: 1;
}
.carousel-main.mobile .title-wrapper {
  border-right: none;
}
.carousel-main.mobile .title-wrapper span {
  font-size: 0.875rem;
  padding: 0 0 0 0.5rem;
}
.carousel-main.mobile .title-wrapper i {
  right: -13%;
}

.trader-wrapper {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #ffffff;
  white-space: nowrap;
  text-align: center;
}
.carousel-main.mobile .trader-wrapper {
  font-size: 0.875rem;
}

.main-stocks {
  color: #4589ff;
  font-family: "Pretendard", Roboto;
  font-size: 1.75rem;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.06rem;
  position: relative;
}
.carousel-main.mobile .main-stocks {
  display: none;
}

.contents-description {
  color: #ffffff;
  font-family: "Gmarket Sans TTF-Bold", Helvetica;
  font-size: 3.125rem;
  font-weight: 700;
  line-height: normal;
  position: relative;
  white-space: pre-line;
  text-align: left;
}
.carousel-main.mobile .contents-description {
  font-size: 1.375rem;
}

.btn-group {
  width: 100%;
  max-width: 23.45rem;
  height: 100%;
  max-height: 3.75rem;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  gap: 0.75rem;
}

.BTN1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.2rem;
  height: 3.75rem;
  border-radius: 0.3rem;
  background: #fff;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  z-index: 50;
}
.BTN2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.125rem;
  height: 3.75rem;
  /* border: 0.0625rem solid #fff; */
  border-radius: 0.3rem;
  background: #fae300;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 50;
  cursor: pointer;
}
.BTN3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.2rem;
  height: 3.75rem;
  border-radius: 0.3rem;
  background: #fff;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  z-index: 50;
}
.carousel-main.mobile .btn-group {
  gap: 0.5rem;
}
.carousel-main.mobile .BTN1,
.carousel-main.mobile .BTN2,
.carousel-main.mobile .BTN3 {
  width: 100%;
}

.expert-broadcast-group {
  display: flex;
  position: relative;
  width: 24.375rem;
  height: 34.375rem;
}
.carousel-main.mobile .expert-broadcast-group {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 20rem;
  height: 100%;
  max-height: 16rem;
  overflow: hidden;
  margin-top: auto;
}

.expert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 550px;
  z-index: 1;
  width: 384px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.expert img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.carousel-main.mobile .expert {
  width: 100%;
  max-width: 15rem;
  height: 100%;
  max-height: 20.625rem;
  overflow: hidden;
}
.carousel-main.mobile .expert img {
  width: 100%;
  height: 100%;
  object-position: top;
  display: block;
}

.expert.image-error {
  background-image: url("@/assets/img/futureon-logo-white-1.png");
}
.expert.image-error img {
  display: none;
}
.carousel-main.mobile .expert.image-error {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.carousel-main.mobile .expert.image-error img {
  display: none;
}

.rectangle-container {
  position: absolute;
  bottom: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #b71c1c;
  border-radius: 3.125rem;
  height: 3.75rem;
  opacity: 0.8;
  width: 75%;
}
.carousel-main.mobile .rectangle {
  width: 100%;
}

.broadcast-time {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 3;
}

.FUTUERON {
  position: absolute;
  top: 42.5%;
  -webkit-text-stroke: 2px #ffffff;
  color: transparent;
  font-family: "Pretendard-Black", Helvetica;
  font-size: 1600%;
  font-weight: 900;
  opacity: 0.08;
  white-space: nowrap;
  transform: scaleY(1.2);
  transform-origin: center;
}
.carousel-main.mobile .FUTUERON {
  display: none;
}
</style>
