<template>
  <div class="best" :class="{ mobile: isMobile }" v-if="processedExpertData.length > 0">
    <div class="best__wrapper">
      <div class="best-main-container">
        <div class="best-content-wrapper" v-for="best in processedExpertData" :key="best.expert_id">
          <div class="best__header">
            <div class="best__header__title">
              <span>{{ best.special_status === 0 ? "전체회원 1위" : "전문가 홈 방문 1위" }}</span>
            </div>
          </div>
          <div class="best-content">
            <div class="best-row1">
              <div class="best-profile">
                <div class="best-profile-image-container" @click="navigateToExpert(best.expert_id)">
                  <div class="best-triangle-background"></div>
                  <div :class="['best-profile-image', { 'image-error': best.imageError }]">
                    <img
                      :src="best.profile_img2"
                      @error="handleImageError(best.expert_id)"
                      alt="Profile Image"
                    />
                  </div>
                  <div v-if="isBroadcastLive(best)" class="best-live-badge-custom">
                    <div class="best-live-custom">
                      <v-icon>mdi-circle</v-icon>
                      <span>LIVE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="best-row2">
              <div class="best-expert-des-container">
                <div class="best-expert-name">
                  <span>{{ best.expert_nickname }}</span>
                </div>
                <div class="best-expert-home-btn" @click="navigateToExpert(best.expert_id)">
                  <img class="img" :alt="`expert_home_svg`" :src="homeSvg" />
                </div>
                <!-- <div class="best-expert-kakao-btn" @click="openKakaoTalk(best.kakao_talk)">
                  <img class="img" :alt="`expert_kakao_svg`" :src="kakaoSvg" />
                </div> -->
              </div>
              <div class="best-title">
                <span>{{ best.title }}</span>
              </div>
              <div
                class="best-date"
                :class="{
                  'best-paid-date': best.type === 0,
                  'best-free-date': best.type === 1,
                }"
              >
                <span>{{ formatDate(best.start_date, best.end_date) }}</span>
              </div>
              <div class="expert-kakao-kakao-talk-btn">
                <button @click="openKakaoTalk(best.kakao_talk)">무료 카톡방</button>
              </div>
              <div
                class="best-btn"
                :class="{
                  'best-paid-broadcast': best.type === 0,
                  'best-bestcast': best.type === 1,
                }"
                @click="joinBroadcast(best, best.expert_id)"
              >
                <span>{{ best.type === 0 ? "유료 방송보기" : "무료 방송보기" }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BestFeatures",
  props: {
    expertData: {
      type: Array,
      default: () => [],
      required: true,
      validator: function (value) {
        if (!Array.isArray(value)) {
          console.error("expertData must be an array");
          return false;
        }
        return true;
      },
    },
  },
  data: () => ({
    homeSvg: require("@/assets/img/expert_home.svg"),
    kakaoSvg: require("@/assets/img/expert_kakao.svg"),
  }),
  computed: {
    ...mapGetters({
      isMobile: "mobile/isMobile",
    }),
    processedExpertData() {
      return this.expertData
        .map((expert) => {
          const now = dayjs();
          const random = Math.floor(Math.random() * 31);
          const start = now.subtract(random, "day").toDate();
          const end = dayjs(start)
            .add(Math.floor(Math.random() * 3) + 1, "hour")
            .toDate();
          return {
            ...expert,
            profile_img2: expert.profile_img2,
            title: expert.title || "방송 제목이 들어옵니다.",
            start_date: expert.start_date || start,
            end_date: expert.end_date || end,
            type: expert.type === 0 || expert.type === 1 ? expert.type : 0,
            imageError: !expert.profile_img2,
          };
        })
        .filter(
          (expert) =>
            expert.leaved === 0 && (expert.special_status === 0 || expert.special_status === 1)
        )
        .sort((a, b) => a.special_status - b.special_status);
    },
  },
  methods: {
    ...mapActions(["validateToken"]),
    formatDate(start, end) {
      const startTime = dayjs(start);
      const endTime = dayjs(end);
      return `${startTime.format("MM월 DD일 HH:mm")}~${endTime.format("HH:mm")}`;
    },
    isBroadcastLive(best) {
      const now = dayjs();
      const start = dayjs(best.start_date);
      const end = dayjs(best.end_date);
      return now.isAfter(start) && now.isBefore(end);
    },
    navigateToExpert(expert_id) {
      if (expert_id) {
        this.$router.push({ name: "LineupLayout", params: { expert_id: expert_id } });
      }
    },
    openKakaoTalk(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    async joinBroadcast(data, expert_id) {
      try {
        let userId = null;
        let reqData = {};

        if (data.type === 0) {
          const userToken = await this.validateToken();

          if (
            !userToken ||
            !userToken.data ||
            !userToken.data.user ||
            userToken.data.user.length === 0
          ) {
            throw new Error("Invalid token data");
          }
          userId = userToken.data.user[0].user_id;
          reqData = {
            user_id: userId,
            expert_id: expert_id,
            base_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "LineupLayout",
              }).href
            }`,
            register_url: `${window.location.origin}/${
              this.$router.resolve({
                name: "RegisterView",
              }).href
            }`,
          };
        } else if (data.type === 1) {
          const userToken = await this.validateToken().catch(() => {
            reqData = { expert_id: expert_id };
          });

          if (
            userToken &&
            userToken.data &&
            userToken.data.user &&
            userToken.data.user.length > 0
          ) {
            userId = userToken.data.user[0].user_id;
            reqData = {
              user_id: userId,
              expert_id: expert_id,
              base_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "LineupLayout",
                }).href
              }`,
              register_url: `${window.location.origin}/${
                this.$router.resolve({
                  name: "RegisterView",
                }).href
              }`,
            };
          }
        }

        const joinUrl = await this.$axios.post(
          `${process.env.VUE_APP_API_URL}/content/join_broadcast`,
          reqData
        );

        if (joinUrl.data.url && joinUrl.status === 200) {
          window.open(joinUrl.data.url, "_blank");
        } else {
          alert("방송중이 아닙니다.");
        }
      } catch (error) {
        console.error("Error in joinBroadcast:", error.message);
        if (error.response && error.response.status === 401) {
          const confirmation = confirm("로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?");
          if (confirmation) {
            this.$router.push("/login");
          }
        } else if (error.response && error.response.data.message === "Invalid broadcast data") {
          alert("방송중이 아닙니다.");
        } else if (error.response && error.response.data.message === "deny") {
          alert("전문가 상품 구매 후 이용 가능한 방송입니다.");
        } else {
          alert("방송중이 아닙니다.");
        }
      }
    },
    handleImageError(expertId) {
      const index = this.processedExpertData.findIndex((expert) => expert.expert_id === expertId);
      if (index !== -1) {
        this.$set(this.processedExpertData, index, {
          ...this.processedExpertData[index],
          imageError: true,
        });
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style scoped>
.best {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 120rem;
  /* height: 100%; */
  height: 100vh;
  max-height: 26.875rem;
  background: #f1f3f8;
}
.best.mobile {
  padding: 1rem 0;
  height: auto;
  max-height: 100%;
}

.best__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 75rem;
  height: 100%;
}

.best__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 4rem;
}
.best.mobile .best__header {
  justify-content: center;
}

.best__header__title {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 1.75rem;
  font-weight: 700;
  text-align: left;
  color: #212121;
  white-space: nowrap;
}

.best-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  width: 100%;
  max-width: 75rem;
  height: 100%;
  max-height: 19.625rem;
  gap: 1.25rem;
}
.best.mobile .best-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}

.best-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 36.565rem;
  height: 100%;
  max-height: 19.625rem;
  gap: 1rem;
}
.best.mobile .best-content-wrapper {
  width: 100%;
  max-width: 30.565rem;
  height: 100%;
  max-height: 19.625rem;
  gap: 1rem;
}

.best-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36.565rem;
  height: 15.4385rem;
  border-radius: 0.625rem;
  border: 0.065rem solid #e0e0e0;
}
.best.mobile .best-content {
  width: 100%;
  max-width: 30.565rem;
  /* height: 100%; */
  /* max-height: 15.4385rem; */
}

.best-row1 {
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  cursor: pointer;
  border-radius: 0.625rem 0 0 0.625rem;
}

.best-row2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.25rem;
  height: 100%;
  max-height: 15.4385rem;
  padding: 0.5rem 0 0 0;
  background: #fff;
  border-radius: 0 0.625rem 0.625rem 0;
}

.best-profile-image-container {
  position: relative;
  width: 18.25rem;
  height: 15.4385rem;
  overflow: hidden;
  border-radius: 0.625rem 0 0 0.625rem;
  background: #bbc2d4;
}
.best.mobile .best-profile-image-container {
  width: 100%;
  max-width: 15.25rem;
  height: 100%;
  max-height: 15.44rem;
}

.best-triangle-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.best-triangle-background::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #b7bed0;
  clip-path: polygon(0 100%, 0 0, 100% 100%);
  z-index: 0;
}

.best-row1 .best-profile {
  width: 100%;
  height: 100%;
}

.best-profile-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.best-profile-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  z-index: 2;
}

.best-profile-image.image-error {
  background-image: url("@/assets/img/futureon-logo-white-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.best-profile-image.image-error img {
  display: none;
}

.best-live-badge-custom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  right: 3%;
  width: 4rem;
  height: 1.5rem;
  opacity: 0.9;
  border-radius: 3.125rem;
  background: #d32f2f;
}

.best-live-custom {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.best-live-badge-custom i {
  color: #fff;
  font-size: 0.5rem;
}

.best-live-badge-custom span {
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
  color: #f5f5f5;
}

.best-expert-des-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 3.75rem;
  gap: 0.4rem;
  padding: 0 0.5rem 0 0.5rem;
}

.best-expert-name {
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
  color: #212121;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.best-expert-home-btn,
.best-expert-kakao-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.best-title {
  position: relative;
  padding: 0 0.5rem 0 0.5rem;
  height: 100%;
  max-height: 3.97rem;
  display: -webkit-box;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  margin-bottom: auto;
}
.best.mobile .best-title {
  max-height: 5.25rem;
}

.best-title span {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
  text-align: left;
  color: #000000;
}

.best-date {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 2rem;
}

.best-date span {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
}

.best-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 3rem;
  border-radius: 0 0 0.3125rem 0;
  cursor: pointer;
}

.best-btn span {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.75rem;
}

.best-paid-date {
  background: #ffebcc;
  color: #e85a1c;
}

.best-free-date {
  background: #e3f2fd;
  color: #0d47a1;
}

.best-record-date {
  background: #52adb01a;
  color: #00acc1;
}

.best-paid-broadcast {
  background: linear-gradient(90deg, #e85a1c -0.17%, #ff9900 100%);
  color: #e3f2fd;
}

.best-bestcast {
  color: #e3f2fd;
  animation: blink 1.5s linear infinite;
}
@keyframes blink {
  0%,
  50% {
    background-color: #0e42aa;
  }
  50.1%,
  100% {
    background-color: #4589ff;
  }
}

.best-record-broadcast {
  background: #00acc1;
  color: #e3f2fd;
}

.expert-kakao-kakao-talk-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 12.5%;
  padding: 10px;
  background: #fae300;
  color: #371c1d;
  font-size: 1rem;
  font-weight: bold;
  line-height: 28px;
}
</style>
